#sunfire-ember {
    width: 100%;
    flex-grow: 1;
    height: 100%;
}

#enrollemtns {
    height: 80vh;
    width: 100vw;
}
