/*@import url('https://unifiedhealthadvisors.com/gapis/css?family=Roboto:300,400,400i,500i,900');*/

* {
    box-sizing: border-box;
}

body {
    padding: 0 !important;
    margin: 0 !important;
    width: 100vw !important;
}
#root {
    width: 100vw !important;
}

.App {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Roboto", sans-serif;
  color: #414042;
  max-width: 100vw;
}
.text_container {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text_container span {
  font-weight: bold;
  font-size: 18px;
}
.title {
  font-size: 36px;
  font-weight: 900;
  line-height: 48px;
  padding-top: 36px;
  color: #1f497d;
}

.subtitle {
  font-size: 20px;
  line-height: 32px;
  color: #6d6e71;
  font-weight: 500;
}

.large_body {
  width: 100%;
  font-size: 20px;
  line-height: 40px;
  animation: large_body 1.5s ease-out 0s 1;
  padding-top: 24px;
}

.body {
  font-size: 18px;
  line-height: 40px;
}

.quote {
  font-family: Georgia, Cambria, "Times New Roman", Times, serif;
  font-size: 24px;
  font-weight: 400;
  font-style: italic;
  color: #727272;
  text-align: center;
  padding: 20px;
}

.center {
  text-align: center;
  text-indent: 0px;
}

.banner {
  width: 100vw;
  height: 36vh;
  overflow: hidden;
}

.whiteSpace16 {
  margin-bottom: 16px;
}
.whiteSpace32 {
  margin-bottom: 32px;
}
.whiteSpace48 {
  margin-bottom: 48px;
}
.whiteSpace64 {
  margin-bottom: 64px;
}
.whiteSpace72 {
  margin-bottom: 72px;
}

.title {
  animation: title 1000ms ease-out 0s 1;
}

.top_button {
  color: #ffff;
  text-transform: uppercase;
  padding: 8px 52px;
  border: 1px solid #ffffff;
  border-radius: 2px;
  margin: 12px 0px;
}
.top_button:hover {
  cursor: pointer;
  color: #df3063;
  border: 1px solid #df3063;
}

@keyframes title {
  0% {
    transform: translateX(-2%);
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
  }
}
@keyframes large_body {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@media (min-width: 769px) {
  .title {
    width: 100%;
    font-size: 48px;
    text-align: left;
  }
  .subtitle {
    font-size: 28px;
    width: 100%;
    font-size: 24px;
    text-align: left;
  }
  .large_body {
    font-size: 20px;
  }
  .banner {
    width: 64vw;
  }

  .top_button {
    margin: 36px 0px;
    padding: 8px 80px;
  }
  .text_container span {
    font-weight: bold;
    font-size: 24px;
  }
  .xs-only {
    display: none;
  }
}

/*sm view*/
@media (min-width: 615px) and (max-width: 768px) {
  .sm-none {display: none}
  .xs-only {display: none}
}

/*xs view*/
@media (max-width: 614px) {
  .xs-none {display: none}
  .xs-only {display: inherit}

  .justify-xs-text {
    text-align: justify;
  }
}